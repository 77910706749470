'use strict';

/**
 * パラメータ取得関数
 * URLのクエリ文字列から指定されたパラメータ名に対応する値を取得します。
 * @param {string} name 取得したいパラメータ名
 * @param {string} url 別ページのURLを使用したい場合
 * @returns {string|null} パラメータの値、またはパラメータが存在しない場合はnull
 */
function getParam(name, url = window.location.search) {
  const params = new URLSearchParams(url);
  const value = params.get(name);
  return value ? sanitizeUrl(value) : null;
}


/**
 * エスケープ関数
 * HTMLエスケープ処理を行うための関数です。
 * @param {string} input エスケープしたい文字列
 * @returns {string} エスケープ処理後の文字列
 */
function sanitizeUrl(input) {
  if (typeof input !== 'string') {
    throw new TypeError('Expected a string');
  }
  return input.replace(/[&'"<>]/g, function(match) {
    return {
      '&': '&amp;',
      "'": '&#x27;',
      '"': '&quot;',
      '<': '&lt;',
      '>': '&gt;',
    }[match];
  });
}


document.addEventListener('DOMContentLoaded', function() {

  /**
   * アンカースクロール
   * @variable headerElement 固定ヘッダーセレクタ
   * @variable headerElement スクロール速度
   */
  const headerClass = '.l-header';

  let scroll = new SmoothScroll('a[href*="#"]', {
    speedAsDuration: true,
    speed: 500,
    header: headerClass,
    easing: 'easeInOutQuint'
  });

  let scrolltop = new SmoothScroll('.m-pagetop', {
    speed: 500,
    speedAsDuration: true
  });

  document.querySelector('.m-pagetop').addEventListener('click', function(e) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' }); // スムーズスクロールを実行
  });
  // ページ表示時のアンカー位置にヘッダーの高さを考慮する
  const headerElement = document.querySelector(headerClass);
  let headerElementHeight = 0;

  // ロード時にヘッダーが存在していれば高さを取得
  if ( headerElement !== null ) {
    headerElementHeight = headerElement.offsetHeight;
  }
  // ハッシュ値があればロード時に高さを考慮した位置へスクロール位置を差し替え
  if ( window.location.hash !== '' ) {
    window.addEventListener('load', function() {
      const target = document.querySelector(window.location.hash);
      if ( target !== null ) {
        const position = target.offsetTop - headerElementHeight;
        document.documentElement.scrollTop = position;
      }
    });
  }



  /**
   * ヘッダーコントロール
   * @variable headerElement 固定ヘッダーセレクタ
   */
  let logoImage = document.querySelector('.l-header_logo_image');
  let headerTransparentFlg = document.querySelector('.is-transparent');

  function checkScroll() {
    if (window.scrollY > 0) {
      headerElement.classList.add('is-scrolled');

      // "white"を"black"に置換
      if (headerTransparentFlg && logoImage && logoImage.src) {
        logoImage.src = logoImage.src.replace('white', 'black');
      }

    } else {
      headerElement.classList.remove('is-scrolled');

      // "black"を"white"に置換
      if (headerTransparentFlg && logoImage && logoImage.src) {
          logoImage.src = logoImage.src.replace('black', 'white');
      }
    }
  }

  if ( headerElement ) {
    // ページロード時に実行
    checkScroll();

    // スクロール時にも実行
    window.addEventListener('scroll', checkScroll);
  }


  /**
   * スクロールトップ
   */
  const pagetopElement = document.querySelector('.m-pagetop');
  if ( pagetopElement ) {
    window.addEventListener('scroll', function() {
      if (window.scrollY > 200) {
        pagetopElement.classList.add('is-show');
      } else {
        pagetopElement.classList.remove('is-show');
      }
    });
  }

  /**
   * スマホフッターのアコーディオン（jQuery）
   */

  if ( $('.l-footer_main_nav_anchor').length ) {
    // アコーディオンアイコンを付与
    $('.l-footer_main_nav_anchor').each(function() {
      if ($(this).next().hasClass('l-footer_main_nav_childList')) {
        $(this).addClass('l-footer_main_nav_anchor-accordion');
      }
    });

    // アコーディオンアイコンを開閉
    $('.l-footer_main_nav_item').on('click', function() {
      if ($(this).find('.l-footer_main_nav_anchor-accordion').length > 0) {
        $(this).find('.l-footer_main_nav_anchor-accordion').next().slideToggle();
        $(this).find('.l-footer_main_nav_anchor-accordion').toggleClass('is-open');
      }
    });
  }


  /**
   * スマホヘッダーのリンク時にメニューを閉じる、ページ無いリンク対応のため（jQuery）
   */
  $('.l-nav_main_anchor').on('click', function() {
    if ( $(this).attr('href').indexOf('#') ) {
      let hash = $(this).attr('href').substring($(this).attr('href').indexOf('#') + 1);
      if ( $('#' + hash).length ) {
        $('.l-nav').removeClass('is-open');
      }
    }
  });
  $('.l-nav_mainChild_anchor').on('click', function() {
    if ( $(this).attr('href').indexOf('#') ) {
      let hash = $(this).attr('href').substring($(this).attr('href').indexOf('#') + 1);
      if ( $('#' + hash).length ) {
        $('.l-nav').removeClass('is-open');
      }
    }
  });


  /**
   * スマホヘッダーのアコーディオン（jQuery）
   */
  if ( $('.l-nav_main_anchor').length ) {
    // アコーディオンアイコンを付与
    $('.l-nav_main_anchor').each(function() {
      if ($(this).next().hasClass('l-nav_mainChild')) {
        $(this).addClass('l-nav_main_anchor-accordion');
      }
    });

    // アコーディオンアイコンを開閉
    $('.l-nav_main_item').on('click', function() {
      if ($(this).find('.l-nav_main_anchor-accordion').length > 0) {
        $(this).find('.l-nav_main_anchor-accordion').next().slideToggle();
        $(this).find('.l-nav_main_anchor-accordion').toggleClass('is-open');
      }
    });

    $(document).on('click', '.l-nav_main_anchor-accordion', function(e) {
      // e.preventDefault();
      $(this).next().slideToggle();
      $(this).toggleClass('is-open');
    });
  }

  $('.l-hamburger').on('click', function() {
    $('.l-nav').addClass('is-open');
  });

  $('.l-hamburgerClose').on('click', function() {
    $('.l-nav').removeClass('is-open');
  });


  /**
   * TOPメインビジュアルの大学名切り替え（jQuery）
   */
  if ( $('.p-top_visual_university_item').length ) {
    $(window).on('resize', function() {
      // 一定時間内に連続して発生するイベントを防ぐためのデバウンス機能
      clearTimeout($.data(this, 'resizeTimer'));
      $.data(this, 'resizeTimer', setTimeout(function() {
        // ここにリロードの処理を記述
        // location.reload();
      }, 200)); // 200ミリ秒のディレイを設定
    });


    let topVisualUniversityItems = $('.p-top_visual_university_item');
    let topVisualUniversityIndex = 0;
    let timer; // タイマーを保存するための変数
    let afterSecondRoundFadeIn = 500;
    let afterSecondRoundFadeOut = 500;

    function showNextItem() {
      clearTimeout(timer); // 現在のタイマーをクリア

      var currentIndex = topVisualUniversityIndex > 0 ? topVisualUniversityIndex - 1 : topVisualUniversityItems.length - 1;

      $(topVisualUniversityItems[currentIndex]).fadeOut(afterSecondRoundFadeOut, function() {
        $(topVisualUniversityItems[topVisualUniversityIndex]).fadeIn(afterSecondRoundFadeIn);

        if ( topVisualUniversityIndex !== topVisualUniversityItems.length - 1) {
          timer = setTimeout(showNextItem, $(topVisualUniversityItems[topVisualUniversityIndex]).data('time') * 1000); // 新しいタイマーを設定
        } else {
          timer = setTimeout(showNextItem, 10 * 1000); // 最後はリセットされるまでの十分な長さをもたせる
        }

        topVisualUniversityIndex++;
        if (topVisualUniversityIndex >= topVisualUniversityItems.length) {
            topVisualUniversityIndex = 0;
        }
        afterSecondRoundFadeIn = 500;
        afterSecondRoundFadeOut = 500;
      });
    }

    $('.p-top_video video').each(function() {
      if ($(this).css('display') !== 'none') {
        $(this).on('loadeddata', function() {
            showNextItem();
        });

        $(this).on('ended', function() {
          // $('.p-top_visual_university_item').hide();
          afterSecondRoundFadeIn = 200;
          afterSecondRoundFadeOut = 100;
          showNextItem();
          this.play();
        });
      }
    });
  }

  // 動画のあるページ（TOP）のみ、ブレーくポイントをまたいだ際にリロード
  // 最初に表示した際に読み込まれていない別デバイス用の動画が最初から再生されるが、
  // 大学名テキストは更新されないため、リロードする
  if ( document.querySelector('.p-top_video') ) {
    // グローバル変数を設定して、画面幅の状態を追跡します
    let isBelow780 = window.innerWidth <= 780;

    function checkWindowSizeAndReload() {
      // 現在の画面幅が780px以下かどうかを確認します
      const currentIsBelow780 = window.innerWidth <= 780;

      // 画面幅が780pxを跨いで変更されたかどうかを確認します
      if (currentIsBelow780 !== isBelow780) {
        // 状態を更新します
        isBelow780 = currentIsBelow780;
        // ページをリロードします
        window.location.reload();
      }
    }

    // resizeイベントにリスナーを追加します
    window.addEventListener('resize', checkWindowSizeAndReload);
  }


  if ( document.querySelector('#js-formSubmit') ) {
    // チェックボックスとボタンの要素を取得
    let agreementCheckbox = document.getElementById('js-agreement');
    let submitButton = document.getElementById('js-formSubmit');

    // チェックボックスの状態に基づいて、.is-activeクラスを切り替える関数
    function updateButtonState() {
      if (agreementCheckbox.checked) {
        submitButton.classList.add('is-active');
      } else {
        submitButton.classList.remove('is-active');
      }
    }

    // ページロード時にボタンの状態を更新
    updateButtonState();

    // チェックボックスの状態が変わった時にボタンの状態を更新
    agreementCheckbox.addEventListener('change', updateButtonState);
  }



  /**
   * アクセシビリティを考慮したスライダー
   * https://ja.splidejs.com/
   */
  /*
  <div class="splide js-slider" aria-label="screen-read-name">
    <div class="splide__track">
      <ul class="splide__list">
        <li class="splide__slide"></li>
      </ul>
    </div>
    <div class="splide__arrows">
      <button class="splide__arrow splide__arrow--prev">Prev</button>
      <ul class="splide__pagination"></ul>
      <button class="splide__arrow splide__arrow--next">Next</button>
    </div>
  </div>
  */
  if ( document.querySelector('.p-top_interview_slider') !== null ) {
    let jsSlider = new Splide('.p-top_interview_slider', {
      perPage       : 2,      // 1ページの表示数
      perMove       : 1,      // 1度にスライドを何枚移動するか
      speed         : 800,    // 移動時間（ミリ秒単位）
      type          : 'loop', // ループする場合
      interval      : 4000,   // 自動再生の間隔（ミリ秒単位）
      pauseOnHover  : false,  // マウスホバー時に自動再生をストップさせない
      resetProgress : false,  // 自動再生ストップ時、経過時間を破棄せず続行する
      updateOnMove  : true,   // is-activeクラスを移動前に更新する
      gap           : '40px', // スライド間の余白（cssと同様）
      padding       : '80px', // 左右の見切れる幅（cssと同様）
      easing        : 'cubic-bezier(0.25, 1, 0.5, 1)', // 移動アニメーション
      focus         : 'left',
      pagination    : false,


      // メディアクエリ設定
      mediaQuery: 'max',
      breakpoints: {
        780: {
          perPage       : 1,      // 1ページの表示数
          padding       : '5.1282051282vw', // 左右の見切れる幅（cssと同様）
          gap           : '5.1282051282vw', // スライド間の余白（cssと同様）
        },
      },
    });

    // スライダーを実行
    jsSlider.mount();
    // jsSlider.mount(window.splide.Extensions);

  }

  /**
   * よくあるご質問の項目開閉
   */
  $('.faq-box-question').on('click', function(){
    $(this).next().slideToggle(300);
    $(this).toggleClass('is-open');
  });

});
